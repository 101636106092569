<template>
  <div class="main row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Phiếu chuyển điểm tiêu dùng'">
        <template v-slot:toolbar>
          <div class="row">
            <div class="ml-3">
              <router-link to="/customer-transfer/add">
                <b-button size="sm" variant="primary">
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  <b>Thêm mới</b>
                </b-button>
              </router-link>
            </div>
          </div>
        </template>
        <template v-slot:preview>
          <div class="mb-5">
            <b-row class="mb-3">
              <b-col cols="2">
                <b-input
                  size="sm"
                  placeholder="Nhập ID phiếu"
                  v-model="pointTransferId"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col cols="3">
                <b-input
                  size="sm"
                  placeholder="Nhập số điện thoại khách chuyển"
                  v-model="phonePointTransfer"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col cols="3">
                <b-input
                  size="sm"
                  placeholder="Nhập số điện thoại khách nhận"
                  v-model="phonePointReceive"
                  v-focus
                  autocomplete="off"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col
                cols="3"
                v-if="checkPermission(['POINT_TRANSTER_VIEW_ALL'])"
              >
                <treeselect
                  :options="storeOptions"
                  :multiple="false"
                  placeholder="Chọn cửa hàng"
                  noResultsText="Không có kết quả"
                  :match-keys="['label']"
                  openDirection="bottom"
                  :clearable="false"
                  v-model="storeId"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span :title="node.label"> {{ node.label }}</span>
                  </label>
                </treeselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="2">
                <treeselect
                  :options="statusOptions"
                  :multiple="false"
                  placeholder="Chọn trạng thái"
                  noResultsText="Không có kết quả"
                  :match-keys="['label']"
                  openDirection="bottom"
                  :clearable="false"
                  v-model="statusFilter"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span :title="node.label"> {{ node.label }}</span>
                  </label>
                </treeselect>
              </b-col>
              <b-col cols="3">
                <b-input
                  size="sm"
                  placeholder="Nhập tên người tạo"
                  v-model="staffCreatePointTransfer"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col cols="3">
                <b-input
                  size="sm"
                  placeholder="Nhập tên người duyệt"
                  v-model="staffConfirmPointTransfer"
                  v-focus
                  autocomplete="off"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1">
                <b-button
                  style="width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                >
                  Lọc
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col cols="12">
              <b-table
                :items="customers"
                :fields="fields"
                bordered
                hover
                :busy="onLoading"
              >
                <template v-slot:table-busy>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="10"
                  ></vcl-table>
                </template>
                <template v-slot:cell(infoCustomerTransfer)="row">
                  <p class="mb-0">
                    {{ row.item.infoCustomerTransfer.fullName }}
                  </p>
                  <strong>
                    {{ row.item.infoCustomerTransfer.phoneNo }}
                  </strong>
                </template>
                <template v-slot:cell(infoCustomerReceive)="row">
                  <p class="mb-0">
                    {{ row.item.infoCustomerReceive.fullName }}
                  </p>
                  <strong>
                    {{ row.item.infoCustomerReceive.phoneNo }}
                  </strong>
                </template>
                <template v-slot:cell(infoUserCreated)="row">
                  <div v-if="row.item.infoUserCreated">
                    <p class="mb-0">
                      {{ row.item.infoUserCreated.employee.fullName }}
                      <template v-if="row.item.infoUserCreated.employee.code">
                        - {{ row.item.infoUserCreated.employee.code }}
                      </template>
                    </p>
                    <strong
                      v-if="
                        row.item.infoUserCreated.employee &&
                          row.item.infoUserCreated.employee.store
                      "
                    >
                      {{ row.item.infoUserCreated.employee.store.name }}
                    </strong>
                  </div>
                  <div v-else>--</div>
                </template>
                <template v-slot:cell(infoUserConfirm)="row">
                  <div v-if="row.item.infoUserConfirm">
                    <p class="mb-0">
                      {{ row.item.infoUserConfirm.employee.fullName }}
                      <template v-if="row.item.infoUserConfirm.employee.code">
                        - {{ row.item.infoUserConfirm.employee.code }}
                      </template>
                    </p>
                    <strong
                      v-if="
                        row.item.infoUserConfirm.employee &&
                          row.item.infoUserConfirm.employee.store
                      "
                    >
                      {{ row.item.infoUserConfirm.employee.store.name }}
                    </strong>
                  </div>
                  <div v-else>--</div>
                </template>
                <template v-slot:cell(status)="row">
                  <div class="text-center">
                    <span
                      style="width: max-content"
                      v-bind:class="{
                        'badge badge-warning text-white':
                          row.item.status === POINT_TRANSFER_STATUS.NEW,
                        'badge badge-success':
                          row.item.status === POINT_TRANSFER_STATUS.COMPLETED,
                        'badge badge-dark':
                          row.item.status === POINT_TRANSFER_STATUS.REJECT,
                      }"
                    >
                      {{ POINT_TRANSFER_STATUS_NAME[row.item.status] }}
                    </span>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-dropdown size="sm" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="fas fa-eye icon-color"
                        ></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số phiếu:
                {{ totalItem }}
              </p>
            </b-col>
            <div class="d-flex justify-content-end">
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>
                  <template v-slot:last-text>
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </template>
                </b-pagination-nav>
              </b-col>
            </div>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  .vue-treeselect__control {
    border-radius: none;
    height: 2.5rem;
  }
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  input.form-control {
    border: 1px solid #ced4da;
  }
  .icon-color {
    color: #464e5f;
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { checkPermission } from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {
  formatDate,
  // convertPrice,
  // makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';
import { POINT_TRANSFER_STATUS } from '@/utils/enum';
import { POINT_TRANSFER_STATUS_NAME } from '@/utils/enum-name';
import { CUSTOMER_GENDER } from '@/utils/constants';

export default {
  data() {
    return {
      POINT_TRANSFER_STATUS,
      POINT_TRANSFER_STATUS_NAME,
      pointTransferId: '',
      phonePointReceive: '',
      phonePointTransfer: '',
      staffCreatePointTransfer: '',
      staffConfirmPointTransfer: '',
      storeOptions: [
        {
          id: -1,
          label: 'Chọn cửa hàng',
        },
      ],
      statusOptions: [
        {
          id: -1,
          label: 'Chọn trạng thái',
        },
        {
          id: 0,
          label: 'Mới',
        },
        {
          id: 1,
          label: 'Duyệt',
        },
        {
          id: 2,
          label: 'Từ chối',
        },
      ],
      statusFilter: -1,
      storeId: -1,
      onLoading: false,
      customerName: '',
      customerDOB: '',
      customerPhone: '',
      customerGender: null,
      genders: CUSTOMER_GENDER,
      fields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: { textAlign: 'center', width: '2%' },
          class: 'text-center',
        },
        {
          key: 'infoCustomerTransfer',
          label: 'Khách chuyển',
          thStyle: { textAlign: 'center', width: '15%' },
          tdClass: 'text-center',
        },
        {
          key: 'point',
          label: 'Số điểm',
          thStyle: { textAlign: 'center' },
          tdClass: 'text-center text-danger font-weight-bold',
        },
        {
          key: 'infoCustomerReceive',
          label: 'Khách nhận',
          thStyle: { textAlign: 'center', width: '15%' },
          tdClass: 'text-center',
        },
        {
          key: 'infoUserCreated',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
          tdClass: 'text-center',
        },
        {
          key: 'infoUserConfirm',
          label: 'Người duyệt',
          thStyle: { textAlign: 'center' },
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { textAlign: 'center' },
          tdClass: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { textAlign: 'center' },
          tdClass: 'text-center',
          formatter: (value) => {
            return formatDate(value, 'DD-MM-YYYY HH:mm:ss');
          },
        },

        { key: 'actions', label: '', class: 'text-center' },
      ],
      customers: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      selectedRowExcel: 'all',
      selectedListExcel: 'current',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      numberOfPage: 1,
      searchLastName: '',
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Treeselect,
    // datePicker,
  },
  created() {
    this.fetchData();
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển điểm tiêu dùng' },
    ]);
  },
  watch: {
    selectedHeader(newVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.optionsHeader.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    checkPermission,
    fetchStore: function() {
      const params = {};
      ApiService.query('/stores/getStores', {
        params: params,
      }).then(({ data }) => {
        const listItem = data.data;
        for (const item of listItem) {
          this.storeOptions.push({
            id: item.id,
            label: item.name,
          });
        }
      });
    },

    onFilter() {
      this.$route.query.page = 1;
      this.fetchData();
    },
    fetchData: function() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      const params = {
        phoneTransfer: this.phonePointTransfer.trim(),
        phoneReceive: this.phonePointReceive.trim(),
        id: this.pointTransferId,
        limit: 10,
        page: this.page,
      };

      if (this.staffCreatePointTransfer) {
        params['staffCreatePointTransfer'] = this.staffCreatePointTransfer.trim();
      }
      if (this.staffConfirmPointTransfer) {
        params['staffConfirmPointTransfer'] = this.staffConfirmPointTransfer.trim();
      }
      if (this.statusFilter !== -1) {
        params['statusFilter'] = this.statusFilter;
      }

      if (this.storeId !== -1) {
        params['storeIdFilter'] = this.storeId;
      }
      ApiService.query('customer/transfer-point', {
        params: params,
      }).then(({ data }) => {
        if (data.status) {
          this.customers = [];
          const dataReq = data.data;
          this.totalItem = dataReq.totalRow;
          this.customers = dataReq.dataset;
          this.numberOfPage = dataReq.totalPage;
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
    editItem: function(item) {
      this.$router.push({
        name: 'customer-transfer-update',
        query: { id: item.id },
      });
    },
  },
};
</script>
